import DataTable from "react-data-table-component";
import artiste from "../assets/images/icons/artist.png";
// import artiste from "../assets/images/artistes.png";

const CustomTitle = ({ row }) => (
  <div className="text-legendr withicon">
    <img src={artiste} width="20" height="20" ></img> {row.AuthorName}
  </div>
);

const columns = [
  {
    name: "Top",
    selector: (row) => row.id,
    width: "10%",
    sortable : true,
  },
  {
    name: "Artiste",
    selector: (row) => row.AuthorName,
    cell: (row) => <CustomTitle row={row} />,
    width: "30%",
    sortable : true,
  },
  ///{
  ///  name: "Genre",
  ///  selector: (row) => row.Genre,
  ///  width: "30%",
  ///  sortable : true,
  ///},
  {
    name: "Nombres de détections",
    selector: (row) => row.count,
    sortable : true,
  },
];

const customStyles = {
  headCells: {
    style: {
      fontSize: "20px",
      fontWeight: "bold",
      padding: "16px 24px",
      justifyContent: "center",
      backgroundColor: "#F7F8FD",
    },
  },
  cells: {
    style: {
      padding: "16px 24px",
      justifyContent: "center",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      backgroundColor: "#fff",
      color: "#0B0F37",
      textAlign: "center",
      fontFamily: "Urbanist, sans-serif",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
    },
  },
};

export default function DatatableArtiste({ data }) {
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
        paginationComponentOptions={{
          rowsPerPageText: "Ligne par page:",
          rangeSeparatorText: "sur",
        }}
      />
    </>
  );
}
